import ReactDOM from 'react-dom'
import React from 'react'
import { Provider } from 'react-redux'

import App from './App'
import './reset.css'
import './index.css'
import './pages/Dashboard.css'
import { ErrorBoundary, FallbackComponent } from './util/ErrorBoundary'
import store from './store'

const rootElement = document.getElementById('root')

const AppWithStore = (
  <Provider store={store}>
    <App />
  </Provider>
)
const theApp = [
  <ErrorBoundary key={'errorboundary'} FallbackComponent={FallbackComponent('page')}>
    {AppWithStore}
  </ErrorBoundary>,
  rootElement,
]

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(...theApp)
} else {
  ReactDOM.render(...theApp)
}
