import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'
import Cookies from 'cookies-js'
import { Route, Router, Switch, Redirect } from 'react-router-dom'
import React, { Component } from 'react'
import connect from 'react-redux/es/connect/connect'
import debounce from 'lodash/debounce'
import Rollbar from 'rollbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft } from '@fortawesome/pro-duotone-svg-icons/faAngleDoubleLeft'
import customHistory from './customHistory'
import {
  AUTH_COOKIE_NAME,
  LISTEN_PORT_COOKIE_NAME,
  API_TARGET,
  WWW_TARGET,
  ROLLBAR_ACCESS_TOKEN,
  COMMIT_HASH,
} from './config'
import store from './store'
import {
  log,
  signOutHandler,
  parseUrlParams,
  createSocket,
  PropsRoute,
  toast,
  apiFetch,
  isValidProfile,
  getCurrentContext,
  setCurrentContext,
  fetchAgentHealth,
  lazyRetry,
} from './util'
import fetchAllDocs, { fetchDomains, fetchRepos } from './fetch'
import './App.css'
import './components/Button.css'
import './components/Pill.css'
import './components/List.css'

import Page from './util/Page'

const PublicTemplateList = React.lazy(() => lazyRetry(() => import('./pages/PublicTemplateList')))
const YamlEditor = React.lazy(() => lazyRetry(() => import('./util/YamlEditor')))
const Footer = React.lazy(() => lazyRetry(() => import('./util/Footer')))
const HostMyContainer = React.lazy(() => lazyRetry(() => import('./sites/HostMyContainer')))
const Home = React.lazy(() => lazyRetry(() => import('./pages/Home/Home')))
const Dashboard = React.lazy(() => lazyRetry(() => import('./pages/Dashboard')))
const Setup = React.lazy(() => lazyRetry(() => import('./pages/Setup')))
const Account = React.lazy(() => lazyRetry(() => import('./pages/Account')))
const TemplateEditor = React.lazy(() => lazyRetry(() => import('./pages/TemplateEditor')))
const Config = React.lazy(() => lazyRetry(() => import('./pages/Config')))
const NotFound = React.lazy(() => lazyRetry(() => import('./pages/NotFound')))
const Privacy = React.lazy(() => lazyRetry(() => import('./pages/Privacy')))
const Pricing = React.lazy(() => lazyRetry(() => import('./pages/Pricing')))
const Support = React.lazy(() => lazyRetry(() => import('./pages/Support')))
const Terms = React.lazy(() => lazyRetry(() => import('./pages/Terms')))
const KubeCon = React.lazy(() => lazyRetry(() => import('./pages/KubeCon')))
const Admin = React.lazy(() => lazyRetry(() => import('./pages/Admin/Admin')))
const Blog = React.lazy(() => lazyRetry(() => import('./pages/Blog')))
const Changelog = React.lazy(() => lazyRetry(() => import('./pages/Changelog')))
const Login = React.lazy(() => lazyRetry(() => import('./pages/Login')))
const UnmarkDeletion = React.lazy(() => lazyRetry(() => import('./pages/UnmarkDeletion')))
const Unsubscribe = React.lazy(() => lazyRetry(() => import('./pages/Unsubscribe')))
const HeaderToolbar = React.lazy(() => lazyRetry(() => import('./util/HeaderToolbar')))
const Definitions = React.lazy(() => lazyRetry(() => import('./pages/Definitions')))
const SystemStatus = React.lazy(() => lazyRetry(() => import('./util/SystemStatus')))
const Notifications = React.lazy(() => lazyRetry(() => import('./util/Notifications')))
const StyleGuide = React.lazy(() => lazyRetry(() => import('./util/StyleGuide')))

// Sidebar width + css "maxContentWidth" + some margin
const MAX_LARGE_WIDTH = 150 + 700 + 200
const REDIRECT_KEY = 'login_redirect'
const gaKey = process.env.REACT_APP_GA_KEY
let gaInitialized = false

customHistory.listen((location, action) => {
  if (gaInitialized) {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
  }
})

class App extends Component {
  state = {
    authed: Cookies.get(AUTH_COOKIE_NAME),
    newData: null,
    small: window.innerWidth / (this.props.showingEditor ? 2 : 1) < MAX_LARGE_WIDTH,
    rollbar: new Rollbar({
      accessToken: ROLLBAR_ACCESS_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
      enabled: COMMIT_HASH !== 'dev',
      environment: process.env.NODE_ENV,
      client: {
        javascript: {
          code_version: COMMIT_HASH,
          source_map_enabled: true,
          guess_uncaught_frames: true,
        },
      },
    }),
  }

  fetchProfile = async () => {
    try {
      const { error, json: profile, status } = await apiFetch('/profile')
      if (status === 401 && (this.state.authed || window.location.pathname !== '/')) {
        return signOutHandler()
      } else if (status !== 200 || !isValidProfile(profile)) {
        // TODO: Conceptually, we should re-try with a back-off here (if error is TypeError or >500)
        let errorType = 'Server Error'
        if (error.name === 'TypeError') {
          errorType = 'Network Error'
        }
        return toast({
          type: 'error',
          msg: `Error fetching your profile: ${errorType}`,
          subMsg: 'Please try again in a moment',
          persistent: false,
        })
      } else if (profile.clusters.length > 0) {
        profile.currentContext = getCurrentContext(profile.clusters)
      } else {
        profile.currentContext = {
          namespace: profile.username,
        }
      }

      this.state.rollbar.configure({
        enabled: true,
        payload: {
          person: {
            id: profile.id,
            username: profile.username,
          },
        },
      })

      store.dispatch({ type: 'SET_PROFILE', profile, loadingProfile: false })
      this.setState({ authed: true })

      const cluster = profile.clusters.find(c => profile.currentContext.address === c.address)
      if (cluster && cluster.shared === false && cluster.verified && cluster.agentKey) {
        await fetchAgentHealth(cluster.agentKey)
      }

      if (window.FS && window.FS.identify) {
        window.FS.identify(profile.id, {
          displayName: profile.username,
          email: profile.email,
        })
      }
    } catch (err) {
      if (this.state.authed) {
        toast({ type: 'error', msg: 'Error fetching your profile', subMsg: err.message, err })
      }
      console.warn('Profile error:', { errMsg: err.message, stack: err.stack })
    }

    const inviteCode = Cookies.get('KSINVITECODE')
    if (inviteCode && inviteCode !== 'complete') {
      toast({
        type: 'success',
        msg: 'Thanks for signing up!',
        subMsg:
          "We're provisioning your resources now - this may take a moment. The page will reload when it's complete!",
      })

      const { status, json } = await apiFetch(`/invite/apply-code/${inviteCode}`, { method: 'PUT' })
      if (status === 200) {
        Cookies.set('KSINVITECODE', 'complete', { expires: 1, sameSite: 'strict' })
        setTimeout(() => {
          window.location = WWW_TARGET
        }, 2500)
      } else {
        if (json && json.expired) {
          Cookies.set('KSINVITECODE', 'complete', { expires: 1, sameSite: 'strict' })
        }
        toast({
          type: 'error',
          msg: 'Error applying invite code',
          subMsg:
            json && json.error
              ? json.error
              : 'Please try reloading the page - the cluster may be offline',
        })
      }
    }
  }

  initializeGA() {
    if (!gaKey || gaInitialized) {
      return
    }

    ReactGA.initialize(gaKey, {
      gaOptions: {
        userId: this.props.profile && this.props.profile.id,
      },
    })

    ReactGA.pageview(window.location.pathname + window.location.search)
    gaInitialized = true
  }

  async componentDidMount() {
    window.rollbar = this.state.rollbar
    window.addEventListener('resize', this.resize)
    let tryAuthFromApiKeys = false
    try {
      const apiKey = window.localStorage.getItem('INTEGRATION_API_KEY')
      const apiSecret = window.localStorage.getItem('INTEGRATION_API_SECRET')
      if (apiKey && apiSecret) {
        Cookies.set(AUTH_COOKIE_NAME, '1', {
          domain: API_TARGET,
          maxAge: 86400 * 1000,
          secure: true,
          sameSite: 'Strict',
        })
        tryAuthFromApiKeys = true
      }
    } catch {}

    const { listenPort } = parseUrlParams()
    if (listenPort) {
      Cookies.set(LISTEN_PORT_COOKIE_NAME, listenPort, {
        expires: new Date(new Date().getTime() + 30 * 60 * 1000),
      })
    }
    if (this.state.authed || tryAuthFromApiKeys) {
      await this.fetchProfile()

      const socket = createSocket()

      socket.on('disconnect', () => {
        log.warn('Socket disconnected!')
      })

      const validProfile =
        isValidProfile(this.props.profile) &&
        this.props.profile.clusters.length > 0 &&
        this.props.profile.currentContext &&
        this.props.profile.currentContext.address &&
        this.props.profile.currentContext.namespace

      if (validProfile) {
        fetchRepos()
      } else {
        store.dispatch({ type: 'STOP_FETCHING' })
      }

      const { clusterAddress, namespace } = parseUrlParams()
      if (clusterAddress && namespace) {
        setCurrentContext(namespace, clusterAddress)
      } else if (validProfile) {
        fetchAllDocs()
        fetchDomains()
      }
    }
    this.initializeGA()
    if (this.handleRedirect()) return
    this.handleNewResourceQuery()
  }

  componentWillUnmount = () => window.removeEventListener('resize', this.resize)

  componentDidUpdate(prevProps) {
    if (this.props.showingEditor !== prevProps.showingEditor) {
      this.resize()
    }
  }

  resize = debounce(() => {
    this.setState({
      small: window.innerWidth / (this.props.showingEditor ? 2 : 1) < MAX_LARGE_WIDTH,
    })
  }, 100)

  handleRedirect() {
    try {
      const redirect = window.localStorage.getItem(REDIRECT_KEY)
      if (redirect) {
        window.localStorage.removeItem(REDIRECT_KEY)
        window.location = WWW_TARGET + redirect
        return true
      }
    } catch {}
  }

  handleNewResourceQuery = async () => {
    const { data, stopRedirectLoop } = parseUrlParams()
    if (!data || window.location.pathname !== '/new') {
      return
    }

    if (!isValidProfile(this.props.profile)) {
      if (stopRedirectLoop) {
        toast({ type: 'error', msg: 'You must sign in to create this Deployment' })
        return
      }
      try {
        window.localStorage.setItem(REDIRECT_KEY, `/new?data=${data}&stopRedirectLoop=true`)
        window.location = `${API_TARGET}/login`
      } catch {}
    }
    let newData = null
    try {
      newData = JSON.parse(data)
    } catch {
      toast({ type: 'error', msg: 'Failed to parse new document data' })
    }
    this.setState({ newData })
  }

  render() {
    const {
      authed,
      // newData,
      small,
    } = this.state
    const { toastStack, loadingProfile } = this.props

    const hostMyContainer =
      window.location.hostname === 'justhostmyfuckingcontainer.com' ||
      window.location.hostname === 'hostmycontainer.com' ||
      window.location.hash === '#jhmfc' ||
      window.location.hash === '#hmc'

    const Loading = (_name, text) => <Page>{text || ''}</Page>
    const Spinner = () => (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '200px',
          height: '400px',
        }}
      >
        Loading...
      </div>
    )

    const toastMessages = toastStack.map(toast => toast.msg)
    const toasts =
      toastStack.length > 0 ? (
        <div className="toasts">
          {toastStack
            .filter(function onlyUnique(toast, index) {
              return toastMessages.lastIndexOf(toast.msg) === index
            })
            .map(toast => {
              const msgCount = toastMessages.filter(msg => msg === toast.msg).length
              const toastClick = () =>
                store.dispatch({
                  type: 'TOAST',
                  toastStack: toastStack.filter(({ id }) => id !== toast.id),
                })
              const type = toast.type || 'info'
              return (
                <div
                  key={toast.id}
                  className={`toast ${type}`}
                  onClick={type === 'info' && !toast.persistent ? toastClick : null}
                >
                  <div className="message">
                    <div className="message-title">
                      {msgCount > 1 ? <div className="toast-multiple">x{msgCount}</div> : null}
                      <span>{toast.msg}</span>
                    </div>
                    {toast.subMsg ? <div className="sub-message">{toast.subMsg}</div> : null}
                  </div>
                  {toast.persistent && !toast.hideDismissButton ? (
                    <div className="dismiss" onClick={toastClick}>
                      ok
                    </div>
                  ) : null}
                </div>
              )
            })}
        </div>
      ) : null

    return (
      <Router history={customHistory}>
        <Helmet>
          <link rel="canonical" href="https://kubesail.com" />
          <meta property="og:title" content="KubeSail" />
          <meta
            property="og:description"
            content="The self-hosting company. Host game-servers like Minecraft, alternatives like PhotoPrism, media-servers like Plex and more. Home-hosting hardware and software."
          />
          <meta property="og:site_name" content="KubeSail.com" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://kubesail.com/" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="500" />
          <meta property="og:image:height" content="321" />
          <meta
            property="og:image"
            content="https://kubesail.com/static/media/pibox-hero-white-with-pi-white.25922cc0.png"
          />
        </Helmet>
        <div className={`App ${this.props.showingEditor ? 'split' : 'full'}`}>
          <div
            title="Open YAML Editor"
            className="open-editor"
            onClick={() => store.dispatch({ type: 'UPDATE_EDITOR', showing: true })}
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} className="open-editor-icon" />
          </div>

          <div className="fullscreen-editor">
            {this.props.showingEditor ? (
              <React.Suspense fallback={Loading('yaml editor', 'Yaml Editor is loading...')}>
                <YamlEditor />
              </React.Suspense>
            ) : null}
          </div>
          {!hostMyContainer ? (
            <React.Suspense fallback={Loading('header toolbar', '')}>
              <HeaderToolbar small={small} showingEditor={this.props.showingEditor} />
            </React.Suspense>
          ) : null}
          {toasts}
          <React.Suspense fallback={Loading('system status', '')}>
            <SystemStatus />
          </React.Suspense>

          <React.Suspense fallback={Loading('router', 'Loading...')}>
            <Switch>
              <PropsRoute exact path="/status" component={SystemStatus} fullPage={true} />
              <PropsRoute exact path="/notifications" component={Notifications} fullPage={true} />
              <PropsRoute exact path="/style-guide" component={StyleGuide} fullPage={true} />
              <PropsRoute
                exact
                path="/repo/new"
                to={`/repos${
                  window.location.search ? window.location.search + '&' : '?'
                }addingRepo=true`}
                component={loadingProfile ? Spinner : this.props.profile ? Redirect : Login}
              />
              {hostMyContainer ? (
                <HostMyContainer />
              ) : (
                <PropsRoute
                  exact
                  path="/:section(platform|resources|app|apps|namespace|deployment|deployments|service|services|volume|volumes|secret|secrets|domain|domains|repo|repos|cluster|clusters|configmap|configmaps)/:name?/:extra?/:item?/:arg1?/:arg2?/:arg3?"
                  to={'/pricing'}
                  small={small}
                  component={authed ? (loadingProfile ? Spinner : Dashboard) : Login}
                />
              )}
              <PropsRoute exact path="/home/:section?" component={Home} />
              <PropsRoute exact path="/pricing" component={Pricing} />
              <PropsRoute
                exact
                path="/setup"
                component={loadingProfile ? Home : this.props.profile ? Setup : Login}
              />
              <PropsRoute
                exact
                path="/register"
                to="/pricing"
                component={loadingProfile ? Home : this.props.profile ? Redirect : Home}
              />
              <PropsRoute exact path="/changelog" component={Changelog} />
              <PropsRoute
                exact
                path="/account"
                component={loadingProfile ? Spinner : this.props.profile ? Account : Login}
              />
              <PropsRoute
                exact
                path="/config"
                component={loadingProfile ? Spinner : this.props.profile ? Config : Login}
              />
              <PropsRoute
                exact
                path="/(unmark-deletion|unreap)"
                component={loadingProfile ? Spinner : this.props.profile ? UnmarkDeletion : Login}
              />
              <PropsRoute
                small={small}
                exact
                path="/templates"
                profile={this.props.profile}
                component={PublicTemplateList}
              />
              <PropsRoute
                small={small}
                exact
                path="/templates/:username/:templateName?"
                component={Dashboard}
              />
              <PropsRoute
                small={small}
                exact
                path="/(template|templates)/:username"
                component={Dashboard}
              />
              <PropsRoute small={small} exact path="/templates-by-tag/:tag" component={Dashboard} />
              <PropsRoute
                small={small}
                exact
                path="/template"
                isNew={true}
                component={TemplateEditor}
              />
              <PropsRoute
                small={small}
                exact
                path="/template/:username/:templateName/:revision?"
                component={TemplateEditor}
              />
              <PropsRoute exact path="/support" component={Support} />
              <PropsRoute exact path="/about" to="/home#about" component={Redirect} />
              <PropsRoute
                exact
                path="/login"
                to="/"
                component={this.props.profile ? Redirect : Login}
              />
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/terms" component={Terms} />
              <PropsRoute path="/blog/:page?" component={Blog} />
              <PropsRoute path="/definitions/:definition?" component={Definitions} />
              <PropsRoute path="/unsubscribe" component={Unsubscribe} />
              <PropsRoute
                exact
                path="/admin/:page?"
                component={this.props.profile ? Admin : NotFound}
              />
              <PropsRoute
                exact
                path="/"
                to="/apps"
                component={
                  authed ? (loadingProfile ? Spinner : this.props.profile ? Redirect : Home) : Home
                }
              />
              <PropsRoute exact path="/kubecon" component={KubeCon} />
              <Route component={NotFound} />
            </Switch>
          </React.Suspense>
          <React.Suspense fallback={Loading('Footer', '')}>
            <Footer />
          </React.Suspense>
        </div>
      </Router>
    )
  }
}

export default connect(({ profile, editor, loadingProfile, toastStack }) => {
  return { profile, showingEditor: editor.showing, loadingProfile, toastStack }
})(App)
