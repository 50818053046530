import Cookies from 'cookies-js'
import { createStore } from 'redux'
import cloneDeep from 'lodash/cloneDeep'
import { docsToWatch } from 'kubesailHelpers'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'

import { AUTH_COOKIE_NAME } from './config'

const INITIAL_TEMPLATE = {
  fetching: true,
  username: '',
  name: '',
  logo: '',
  data: '',
  revision: 0,
  stars: 0,
  official: false,
  youStarred: false,
  invalidDocCount: 0,
  description: '',
  isPrivate: true,
  pendingIsPrivate: true,
  editingDescription: false,
  descriptionPreview: false,
  newDescription: '',
  editingPrivacy: false,
}

const initialState = {
  docs: [],
  pendingDocs: [],
  templateDocs: [],
  domains: [],
  repos: [],
  repoBuilds: [],
  profile: null,
  loadingProfile: Cookies.get(AUTH_COOKIE_NAME),
  toastStack: [],
  targetDocs: [],
  expandedDocs: [],
  volumeBackups: [],
  volumeRestores: [],
  docFilter: {
    names: [],
    kinds: [],
  },
  notifications: [],
  fetchError: false,
  fetching: {}, // fetching kinds are added from docsToWatch below
  editor: {
    showing: false,
    title: '',
    stars: null,
    youStarred: false,
    tab: null,
    isTemplate: false,
    isPrivate: true,
  },
  template: INITIAL_TEMPLATE,
  gatherImageMetadata: {},
  templateHasUnsavedChanges: false,
}

docsToWatch.forEach(doc => {
  initialState.fetching[doc.kind.toLowerCase()] = true
})
initialState.fetching.domain = true
initialState.fetching.repo = true

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_PROFILE': {
      return { ...state, profile: action.profile, loadingProfile: action.loadingProfile || false }
    }
    case 'TOAST': {
      return { ...state, toastStack: action.toastStack }
    }
    case 'VOLUME_BACKUPS': {
      return { ...state, volumeBackups: action.volumeBackups }
    }
    case 'VOLUME_RESTORES': {
      return { ...state, volumeRestores: action.volumeRestores }
    }
    case 'FETCH_ERROR': {
      return { ...state, fetchError: true }
    }
    case 'REPOS_UPDATE': {
      return { ...state, repos: action.repos, fetching: { ...state.fetching, repo: false } }
    }
    case 'REPO_BUILDS_UPDATE': {
      return { ...state, repoBuilds: uniqBy([...action.repoBuilds, ...state.repoBuilds], 'uuid') }
    }
    case 'DOCS_UPDATE': {
      return {
        ...state,
        docs: action.docs || state.docs,
        fetching: { ...state.fetching, [action.fetchKind]: false },
        pendingDocs: action.pendingDocs || state.pendingDocs,
        expandedDocs: action.expandedDocs || state.expandedDocs,
        templateDocs: action.templateDocs || state.templateDocs,
        targetDocs: action.targetDocs || state.targetDocs,
        templateHasUnsavedChanges:
          typeof action.templateHasUnsavedChanges === 'boolean'
            ? action.templateHasUnsavedChanges
            : state.templateHasUnsavedChanges,
      }
    }
    case 'PENDING_DOCS_UPDATE': {
      return {
        ...state,
        pendingDocs: action.pendingDocs,
        templateDocs: action.templateDocs || state.templateDocs,
        expandedDocs: action.expandedDocs || state.expandedDocs,
      }
    }
    case 'SET_PENDING_DOC': {
      let matchedExisting = false
      const pendingDocs = state.pendingDocs.map(doc => {
        const kind = get(doc, 'kind') === get(action.doc, 'kind')
        const name = get(doc, 'metadata.name') === get(action.doc, 'metadata.name')
        const kubesailEditorUid =
          get(doc, 'metadata.annotations.kubesailEditorUid') &&
          get(doc, 'metadata.annotations.kubesailEditorUid') ===
            get(action.doc, 'metadata.annotations.kubesailEditorUid')

        if ((name && kind) || kubesailEditorUid) {
          matchedExisting = true
          return action.doc
        }
        return doc
      })
      if (!matchedExisting) pendingDocs.push(action.doc)
      return { ...state, pendingDocs }
    }
    case 'DOMAINS_UPDATE': {
      let domains = action.domains || state.domains
      if (action.domain) {
        let foundDomain = false
        domains = domains.map(domain => {
          if (domain.name === action.domain.name) {
            foundDomain = true
            return action.domain
          } else {
            return domain
          }
        })
        if (!foundDomain) domains.push(action.domain)
      }
      return {
        ...state,
        domains,
        fetching: { ...state.fetching, domain: false },
      }
    }
    case 'STOP_FETCHING': {
      return { ...state, fetching: {} }
    }
    case 'FETCHING_ALL': {
      const fetching = cloneDeep(state.fetching)

      docsToWatch.forEach(doc => {
        fetching[doc.kind.toLowerCase()] = true
      })
      return {
        ...state,
        fetching,
        fetchError: false,
        docs: [],
        domains: [],
        editor: { ...state.editor },
      }
    }
    // TARGET_DOCS is used by the YAML editor to know which docs to show in the tabs
    case 'TARGET_DOCS': {
      return { ...state, targetDocs: action.targetDocs }
    }
    // EXPANDED_DOCS is used by UI
    case 'EXPANDED_DOCS': {
      return { ...state, expandedDocs: action.expandedDocs }
    }
    case 'FILTERED_DOCS': {
      return { ...state, docFilter: action.docFilter }
    }
    case 'NOTIFICATIONS': {
      return { ...state, notifications: action.notifications }
    }
    case 'UPDATE_EDITOR': {
      let showing = true
      if (action.showing !== undefined) showing = action.showing
      else if (action.noOpen) showing = state.editor.showing
      else if (action.yaml) showing = true
      else showing = !!(state.targetDocs && state.targetDocs.length > 0)
      const targetDocs = [...state.targetDocs]
      if (
        action.tab &&
        !targetDocs.find(({ kind, name }) => action.tab.kind === kind && action.tab.name === name)
      ) {
        targetDocs.push(action.tab)
      }
      return {
        ...state,
        targetDocs,
        expandedDocs: action.expandedDocs || state.expandedDocs,
        editor: {
          tab: action.tab || action.tab === null ? action.tab : state.editor.tab,
          showing,
          title: action.title || state.editor.title,
          yaml: action.yaml || state.editor.yaml,
          revision: action.revision || state.editor.revision,
          isTemplate:
            typeof action.isTemplate === 'boolean' ? action.isTemplate : state.editor.isTemplate,
          isPrivate:
            typeof action.isPrivate === 'boolean' ? action.isPrivate : state.editor.isPrivate,
          templateUsername: action.templateUsername || state.editor.templateUsername,
          name: action.name || state.editor.name,
          scrollToDocument:
            typeof action.scrollToDocument === 'number'
              ? action.scrollToDocument
              : state.editor.scrollToDocument,
        },
      }
    }
    case 'RESET_TEMPLATE': {
      return { ...state, template: INITIAL_TEMPLATE }
    }
    case 'TEMPLATE_UPDATE': {
      const template = cloneDeep(action)
      delete template.type
      if (!template.tags) template.tags = []
      return {
        ...state,
        template: {
          ...state.template,
          ...template,
        },
        templateHasUnsavedChanges:
          typeof action.templateHasUnsavedChanges === 'boolean'
            ? action.templateHasUnsavedChanges
            : state.templateHasUnsavedChanges,
        templateDocs: action.templateDocs || state.templateDocs,
      }
    }
    case 'SET_IMAGE_METADATA': {
      return {
        ...state,
        gatherImageMetadata: action.gatherImageMetadata,
      }
    }
    default:
      return state
  }
}

const store = createStore(rootReducer)

window.__STORE = store

export default store
