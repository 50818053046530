// @flow

export const API_TARGET = process.env.REACT_APP_API_TARGET || 'https://api.kubesail.com'
export const WWW_TARGET = process.env.REACT_APP_WWW_TARGET || 'https://kubesail.com'
export const WSS_TARGET = process.env.REACT_APP_WSS_TARGET || 'wss://api.kubesail.com'
export const BYOC_TARGET = process.env.REACT_APP_BYOC_TARGET || 'https://byoc.kubesail.com'

// Must match service configuration
export const AUTH_COOKIE_NAME = process.env.REACT_APP_AUTH_COOKIE_NAME || 'KSAC'
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN || 'localhost'

// Local Storage keys for storing current context
export const KUBESAIL_NAMESPACE_KEY =
  process.env.REACT_APP_KUBESAIL_NAMESPACE_KEY || 'kubesailNamespace'
export const KUBESAIL_CLUSTER_ADDRESS_KEY =
  process.env.REACT_APP_KUBESAIL_CLUSTER_ADDRESS_KEY || 'kubesailClusterAddress'
export const KUBESAIL_LAST_USED_CONTEXT_KEY = 'kubesailLastUsedContext'

export const ROLLBAR_ACCESS_TOKEN =
  process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN || 'ba0f0ecde6d64a47874d2006a049d3b2'

export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || 'pk_test_7AkRzftaxjOZWEiGFNy7sPYP'
export const STRIPE_OAUTH_CLIENT_ID =
  process.env.REACT_APP_STRIPE_OAUTH_CLIENT_ID || 'ca_IzBT1oLJMtWrTweD5ckO60o5imtrp8VF'
export const STRIPE_TEST_OAUTH_CLIENT_ID =
  process.env.REACT_APP_STRIPE_TEST_OAUTH_CLIENT_ID || 'ca_IzBTx9Y6NMt1TSB4apkZekg3IECHzfNS'
export const COMMIT_HASH = process.env.REACT_APP_COMMIT_HASH || 'dev'
window.COMMIT_HASH = COMMIT_HASH
export const GITHUB_DEPLOYBOT_NAME =
  process.env.REACT_APP_GITHUB_DEPLOYBOT_NAME || 'kubesail-deploybot-dev'

export const LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL || COMMIT_HASH === 'dev' ? 'debug' : 'info'

// Callback port that the kubesail register-cli package listens on
export const LISTEN_PORT_COOKIE_NAME = 'listenPort'
export const LOGIN_REDIRECT_COOKIE_NAME = 'loginRedirect'

export const SITE_TITLE_PREFIX =
  process.env.NODE_ENV === 'development' ? 'DevSail | ' : 'KubeSail | '

export const CLUSTER_HEALTH_LIVE = 'live'
export const CLUSTER_HEALTH_CACHED = 'cached'
export const CLUSTER_HEALTH_UNREACHABLE = 'unreachable'
export const CLUSTER_HEALTH_UNAUTHORIZED = 'unauthorized'
export const ADMIN_USERS = ['PastuDan', 'erulabs']
